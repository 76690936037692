<template>
  <CabinetPage :preloader="preloader">
    <div class="mx-cabinet-page-header"></div>
      <div class="mx-card">
        <h5 class="mx-title_border">
          <div>
            <span>{{ $t('document.title') }}</span>
          </div>
          <router-link :to="{name: 'document.create'}" class="btn btn-peepz-success">
            <ph-plus-circle :size="22"/>
            <span>{{ $t('document.btn.new_document') }}</span>
          </router-link>
        </h5>
<!--        <TableDocuments table_class="mx-table-grid__document" table_id="mx-table-documents"></TableDocuments>-->


        <div class="row" v-if="!preloader">
          <div class="col-md-3 col-lg-2">
            <div class="list-group" >
              <button
                  href="#"
                  class="list-group-item list-group-item-action"
                  :class="{'active': category.id === category_id && !myDocuments}"
                  v-for="(category, index) in getCategories"
                  :key="index"
                  @click.prevent="getCategory(category.id)"
                  :disabled="category.disabled"
              >
                {{ category.name }}
              </button>

              <button
                  href="#"
                  class="list-group-item list-group-item-action"
                  :class="{'active': myDocuments}"
                  @click.prevent="getCustomerDocuments"
              >
                Meine Dokumente
              </button>
            </div>
          </div>
          <div class="col-md-9 col-lg-10">
            <table v-if="documents.length > 0" id="mx-table-documents" class="mx-table mx-table-style__main mx-table-grid__document">
              <thead>
              <tr>
                <th data-type="text-short">{{ $t('document.table.head.name') }}<span class="resize-handle"></span></th>
                <th data-type="text-short">{{ $t('document.table.head.type') }}<span class="resize-handle"></span></th>
                <th data-type="text-short">{{ $t('document.table.head.category') }}<span class="resize-handle"></span></th>
                <th data-type="text-short">{{ $t(`document.table.head.created_at`) }}<span class="resize-handle"></span></th>
                <th data-type="text-short">{{ $t('document.table.head.updated_at') }}<span class="resize-handle"></span></th>
                <th data-type="text-long"><span class="resize-handle"></span></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in getCDocuments" :key="item.id">
                <td>
                  <a target="_blank" :href="options.document_url + item.path">{{ item.name }}</a>
                </td>
                <td>{{ item.file_type }}</td>
                <td>{{  getCategoryName(item.category_id) }}</td>
                <td>{{ item.created_at }}</td>
                <td>{{ item.updated_at }}</td>
                <td>
                  <template v-if="item.created_customer_id === $store.getters.user.uid">
                    <router-link :to="{name: 'document.edit', params: {'id': item.id}}">
                      <ph-pencil-simple :size="22"/>
                    </router-link>
                  </template>
                </td>
              </tr>
              </tbody>
            </table>
            <strong v-else class="mx-notfound">
              {{ $t('document.table.not_found') }}
              <slot></slot>
            </strong>
          </div>
        </div>


      </div>
  </CabinetPage>
</template>

<script>
import CabinetPage from "@/components/main/CabinetPage";
import {PhPlusCircle, PhPencilSimple} from "phosphor-vue";

export default {
  name: "DocumentShowView",
  components: {CabinetPage, PhPlusCircle, PhPencilSimple},
  data() {
    return {
      myDocuments: false,
      preloader: true,
      category_id: 1,
      documents: [],
      categories: []
    }
  },
  computed: {
    getCDocuments() {
      if (this.category_id === 1 && !this.myDocuments) {
        return this.documents
      }

      if (this.myDocuments) {
        return this.documents.filter( document => {
          return document.created_customer_id === this.$store.getters.user.uid
        })
      }

      return this.documents.filter( document => {
        return document.category_id === this.category_id
      })
    },
    getCategories() {
      return this.categories.map(category => {
        category['disabled'] = this.documents.findIndex(document => document.category_id === category.id) === -1 && category.id !== 1
        return category
      })
    }
  },
  created() {
    setTimeout(this.tableResizing, 300)
    this.getDocuments()
  },
  methods: {
    getCustomerDocuments() {
      this.myDocuments = true
    },
    getCategory(id) {
      this.category_id = id
      this.myDocuments = false
    },
    getCategoryName(id) {
      return this.categories.find(c => c.id === id).name
    },
    getDocuments() {
      this.$store.dispatch('allDocuments').then(() => {
        this.documents = this.$store.getters.documents
        this.$store.dispatch('documentCategories').then(() => {
          this.categories = this.$store.getters.document_categories
          this.preloader = false
        })
      })
    },
    tableResizing() {
      const min = 150;
      const columnTypeToRatioMap = {
        numeric: 1,
        'text-short': 1.67,
        'text-long': 3.33,
      };

      const table = document.querySelector(`#${this.table_id}`);
      const columns = [];
      let headerBeingResized;

      const onMouseMove = (e) => requestAnimationFrame(() => {
        let horizontalScrollOffset = document.documentElement.scrollLeft;
        const width = (horizontalScrollOffset + e.clientX) - headerBeingResized.offsetLeft;

        const column = columns.find(({header}) => header === headerBeingResized);
        column.size = Math.max(min, width) + 'px';

        table.style.gridTemplateColumns = columns
            .map(({header, size}) => size)
            .join(' ');
      });

      const onMouseUp = () => {
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mouseup', onMouseUp);
        headerBeingResized.classList.remove('header--being-resized');
        headerBeingResized = null;
      };

      const initResize = ({target}) => {
        headerBeingResized = target.parentNode;
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
        headerBeingResized.classList.add('header--being-resized');
      };

      document.querySelectorAll(`#${this.table_id} th`).forEach((header) => {
        const max = columnTypeToRatioMap[header.dataset.type] + 'fr';
        columns.push({
          header,
          size: `minmax(${min}px, ${max})`,
        });
        header.querySelector(`#${this.table_id} .resize-handle`).addEventListener('mousedown', initResize);
      });
    }
  }
}
</script>

<style scoped>
.list-group-item.list-group-item-action.active {
  background-color: #fd9843;
  border-color: #fd9843;
}
</style>
